<template>
  <div class="my-8">
    <div class="d-flex">
      <h3 class="font-family-raleway-bold">
        {{ $t("profile_page.billing_information_title") }}
      </h3>
      <v-spacer />
      <!--add new card dialog -->
      <AddNewCardDialog
        v-if="dialog"
        :dialog="dialog"
        @close="dialog = false"
      />
    </div>
    <p class="mt-3 font-family-raleway-medium">
      {{ $t("profile_page.billing_information_description") }}
    </p>

    <v-row class="mt-4 mb-10">
      <v-col md="3" cols="12">
        <h5 class="font-family-raleway-medium">{{$t("profile_page.full_name_input")}}</h5>
        <v-text-field
          :value="getUser.fullName"
          :class="false ? 'bg-white' : 'bg-textField'"
          :hide-details="true"
          class="mt-2 profile-settings-textfield font-family-raleway-medium bg-textField"
          height="45"
          readonly
          rounded
          dense
        ></v-text-field>
      </v-col>
      <v-col md="3" cols="12">
        <h5 class="font-family-raleway-medium">{{ $t('profile_page.company_name_input') }}</h5>
        <v-text-field
          :value="getUser.companyName"
          :class="false ? 'bg-white' : 'bg-textField'"
          :hide-details="true"
          class="mt-2 profile-settings-textfield font-family-raleway-medium bg-textField"
          height="45"
          readonly
          rounded
          dense
        ></v-text-field>
      </v-col>
      <v-col md="4" cols="12">
        <h5 class="font-family-raleway-medium">{{ $t("email_input") }}</h5>
        <v-text-field
          :value="getUser.email"
          :class="false ? 'bg-white' : 'bg-textField'"
          :hide-details="true"
          class="mt-2 profile-settings-textfield font-family-raleway-medium bg-textField"
          height="45"
          readonly
          rounded
          dense
        ></v-text-field>
      </v-col>
      <!--      <AddCardWithStripe />-->
    </v-row>
  </div>
</template>

<script>
import deleteButton from "../../../assets/icon/delete-button.svg";
import { mapGetters } from "vuex";
export default {
  components: {
    AddNewCardDialog: () => import("@/components/Account/user-settings/AddNewCardDialog")
  },
  name: "BillingInformation",
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      isEditBilling: false,
      loadingUpdateBilling: false,
      isdelete: false,
      dialogItem: {},
      deleteButton,
    };
  },
  methods: {
    handleCancelEditingBilling() {
      this.loadingUpdateBilling = false;
      this.isEditBilling = false;
    },
    async onSaveChanges() {
      this.isEditBilling = false;
    },
    isActive() {
      this.dialogDelete = !this.dialogDelete;
    },
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
};
</script>

<style scoped lang="scss">
.profile-settings-textfield {
  border-radius: 10px !important;
  width: 100%;
}

.bg-white {
  background-color: #ffffff !important;
}
</style>
